import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/AuthContext";
import AlertContext from "../../context/alert/AlertContext";
import Alert from "../layout/Alert";
// import Paginations from '../../utils/Pagination'
import Spinner from "../layout/Spinner";
import { Link } from 'react-router-dom';

const GetDebtExs = () => {
    const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const {
    getCollectors,
    collectors,
    loading,
  } = authContext;
  useEffect(() => {
    getCollectors();
    // if (activatedUser) {
    //     if (activatedUser.message === "User Activated") {
    //       setAlert(activatedUser.message, "success");
    //       clearErrors();
    //     }
    //   }
    //   if (deactivatedUser) {
    //     if (deactivatedUser.message === "User Blocked") {
    //       setAlert(deactivatedUser.message, "success");
    //       clearErrors();
    //     }
    //   }
    // eslint-disable-next-line
  }, []);
  console.log(collectors);
  let loaded_users = [];
  if (collectors) {
    loaded_users=collectors
  } else {
    loaded_users = [];
  }

    return (
        <div>
             <h2>Staff</h2>
            <Alert />
            <div>
              <div className="card mb-2 text-center border border-primary">
                <div >
                <Link to="/register_debt"
                type="button"
                className="btn btn-primary mb-1 mt-1"
              >
                Add User
              </Link>
                </div>
              </div>
            </div>
            {collectors !== null && !loading ? (
              <section className="content-info overflow-auto">
                <table className="table table-striped table-bordered table-hover">
                  <thead className="">
                    <tr>
                      <th className="text-center"> Name</th>
                      <th className="text-center">Title</th>
                      <th className="text-center">Email</th>
                      <th className="text-center">Status</th>
                      {/* <th className="text-center">Edit</th>
                      <th className="text-center">Action</th> */}
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {loaded_users.map((option) => (
                      <tr key={option.id}>
                        <td>{option.first_name} {option.last_name}</td>
                        <td>{option.usertype.description}</td>
                        <td>{option.email}</td>
                        <td>
                          {option.is_active ? (
                            <p className="text-primary">Active</p>
                          ) : (
                            <p className="text-danger">Blocked</p>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination-wrapper mt-6">
                  <div>
          </div>
        </div>
              </section>
            ) : (
              <div>
                <h5 className="text-center">Loading users, please wait...</h5>
                <Spinner />
              </div>
            )}
          </div>
        
    )
}


export default GetDebtExs
