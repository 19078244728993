import React, { useContext, useEffect } from "react";
import AuthContext from "../../context/auth/AuthContext";
import { Pie } from 'react-chartjs-2';

const Dashboard = () => {
    const authContext = useContext(AuthContext);
    const {
        stats,
        GetStats,
        loading,
        error      
      } = authContext;

      useEffect(() => {
        GetStats();
       
        // eslint-disable-next-line
      }, [error,stats]);
      let totalInvoice = ''
      let assignedInvoice = ''
      let unassigned = ''
      let zero = ''
      let paid = ''
      if(stats){
          totalInvoice = stats.total_invoices
          assignedInvoice = stats.assigned
          unassigned = totalInvoice-assignedInvoice
          zero = stats.zero_paid
          paid = totalInvoice-zero

      }

      const data = {
        labels: ['Unassigned','Assigned'],
        datasets: [
          {
            label: 'Invoices',
            data: [unassigned,assignedInvoice],
            backgroundColor: [
              '#979797',
              '#439CD3',
              
            ],
            borderColor: [
              '#5B5D5C',
              '#439CD3',
            ],
            borderWidth: 1,
          },
        ],
      };

      const data1 = {
        labels: ['zero paid','some amount paid'],
        datasets: [
          {
            label: 'Payment',
            data: [zero,paid],
            backgroundColor: [
              '#979797',
              '#439CD3',
              
            ],
            borderColor: [
              '#5B5D5C',
              '#439CD3',
            ],
            borderWidth: 1,
          },
        ],
      };
      
    return (
        <div>
            <h1>dashboard</h1>
            <div className="row">

        <div class="col-lg-4">
          <div class="card border-left-primary shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Total Invoices
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                     {totalInvoice}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="bi bi-calendar3-event-fill fa-2x text-secondary"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card border-left-primary shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Assigned Invoices
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                   {assignedInvoice}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="bi bi-calendar3-event-fill fa-2x text-secondary"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card border-left-primary shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Unassigned Invoices
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {unassigned}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="bi bi-calendar3-event-fill fa-2x text-secondary "></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
          <div className="col-lg-4">
             <h3 className="text-center">Assigned Vs UnAssigned</h3>
          <Pie data={data} />
          </div>
          <div className="col-lg-4">
          <h3 className="text-center">Invoices with Zero Payment</h3>
          <Pie data={data1} />
          </div>
          <div className="col-lg-4"></div>
      </div>
        </div>
    )
}

export default Dashboard
