import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/AuthContext";
import AlertContext from "../../context/alert/AlertContext";
import Alert from "../layout/Alert";
// import Paginations from '../../utils/Pagination'
import Spinner from "../layout/Spinner";
import { Link } from 'react-router-dom';

const ManageUsers = () => {
    const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const {
    getUsers,
    allusers,
    loading,
    activateUser,
    activatedUser,
    deactivateUser,
    deactivatedUser,
    clearErrors,
    getApproveUsers,
    approveusers,
  } = authContext;
  useEffect(() => {
    getUsers();
    getApproveUsers()
    if (activatedUser) {
        
          setAlert(activatedUser.message, "success");
          clearErrors();
        
      }
      if (deactivatedUser) {
        
          setAlert(deactivatedUser.message, "success");
          clearErrors();
        
      }
    // eslint-disable-next-line
  }, [allusers,approveusers]);
  console.log(allusers);
  let loaded_users = [];
  if (allusers) {
    loaded_users=allusers
  } else {
    loaded_users = [];
  }

  let approve_users = [];
  if (approveusers) {
   approve_users=approveusers
  } else {
    approve_users = [];
  }

  let numb=approve_users.length;

  const Deactivate = (item) => (e) => {
    e.preventDefault();
    // console.log(item);
   deactivateUser(item)
  };

  const Activate = (item) => (e) => {
    e.preventDefault();
    activateUser(item)
    
  };

    return (
        <div>
            <Alert />
            <div className="row">
                <div className="col-lg-3"><h1 className='text-primary'>Users</h1></div>
                <div className="col-lg-7"></div>
                <div className="col-lg-2">
                <div className=" mb-2 text-center">
                <div >
                <Link to="/approve"
                type="button"
                className="btn btn-primary mb-1 mt-1 position-relative"
              >
                Approve Users
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
    {numb}
    <span className="visually-hidden">unread messages</span>
  </span>
              </Link>
                </div>
              </div>
                </div>
            </div>
             
            
            <div>
              <div className="card mb-2 text-center border border-primary">
                <div >
                <Link to="/register"
                type="button"
                className="btn btn-primary mb-1 mt-1"
              >
                Add User
              </Link>
                </div>
              </div>
            </div>
            {allusers !== null && !loading ? (
              <section className="content-info overflow-auto">
                <table className="table table-striped table-bordered table-hover">
                  <thead className="">
                    <tr>
                      <th className="text-center"> Name</th>
                      <th className="text-center">Title</th>
                      <th className="text-center">Email</th>
                      <th className="text-center">Status</th>
                      {/* <th className="text-center">Edit</th> */}
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {loaded_users.map((option) => (
                      <tr key={option.id}>
                        <td>{option.first_name} {option.last_name}</td>
                        <td>{option.usertype.description}</td>
                        <td>{option.email}</td>
                        <td>
                          {option.is_active ? (
                            <p className="text-primary">Active</p>
                          ) : (
                            <p className="text-danger">Blocked</p>
                          )}
                        </td>
                        {/* <td>
                        <Link to={`/edit_user/${option.id}`}
                          className="btn btn-success btn-sm"
                        >
                          Edit
                        </Link>
                        </td> */}
                        <td>
                          {option.is_active ? (
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={Deactivate(option.id)}
                              type="button"
                            >
                              Block
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={Activate(option.id)}
                              type="button"
                            >
                              Activate
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination-wrapper mt-6">
                  <div>
          </div>
        </div>
              </section>
            ) : (
              <div>
                <h5 className="text-center">Loading users, please wait...</h5>
                <Spinner />
              </div>
            )}
          </div>
        
    )
}

export default ManageUsers
