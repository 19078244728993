import React, { useContext, useEffect } from "react";
import AuthContext from "../context/auth/AuthContext";
import {
  BrowserRouter as Router,
  Route,
  Switch,
//   Redirect,
} from "react-router-dom";
import { Fragment } from "react";
import Login from "./auth/Login";
import Dashboard from "./pages/Dashboard";
import Navbar from "./layout/Navbar";
import PrivateRoute from "../routing/PrivateRoute";
import PassChange from "./auth/PassChange";
import GetDebtExs from "./auth/GetDebtExs";
import ManageUsers from "./auth/ManageUsers";
import ApproveUser from "./auth/ApproveUser";
import CreateUsers from "./auth/CreateUsers";
import CreateDebtExecs from "./auth/CreateDebtExecs";
import InvoiceAssign from "./Invoices/InvoiceAssign";
import InvoiceStatus from "./Invoices/InvoiceStatus";
import CustomerAssign from "./Invoices/CustomerAssign";

const RouteComponent = () => {
  const authContext = useContext(AuthContext);
  const { loadUser, user } = authContext;

  useEffect(() => {
    loadUser();

    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Router>
        <Fragment>
          <Switch>
            <Route exact path="/" component={Login} />
            <Fragment>
            <Navbar />
            {user && (
            <div className="">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-9 shadow-sm p-3 mb-5  mt-5">
                <Fragment>
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/pass_change" component={PassChange} />
            <PrivateRoute exact path="/customer_assign" component={CustomerAssign} />
            <PrivateRoute exact path="/collectors" component={GetDebtExs} />
            <PrivateRoute exact path="/users" component={ManageUsers} />
            <PrivateRoute exact path="/approve" component={ApproveUser} />
            <PrivateRoute exact path="/register" component={CreateUsers} />
            <PrivateRoute exact path="/register_debt" component={CreateDebtExecs} />
            <PrivateRoute exact path="/invoice_assign" component={InvoiceAssign} />
            <PrivateRoute exact path="/invoice_status" component={InvoiceStatus} />
            </Fragment>
                </div>
                <div className="col-lg-1"></div>
              </div>
                
            </div>
            )}
            </Fragment>
            
          </Switch>
        </Fragment>
      </Router>
    </div>
  );
};

export default RouteComponent;
