export const REGISTER_SUCCESS='REGISTER_SUCCESS'
export const LOGIN_SUCCESS='LOGIN_SUCCESS'
export const REGISTER_FAIL='REGISTER_FAIL'
export const USER_LOADED='USER_LOADED'
export const AUTH_ERROR='AUTH_ERROR'
export const LOGIN_FAIL='LOGIN_FAIL'
export const LOGOUT='LOGOUT'
export const CLEAR_ERRORS='CLEAR_ERRORS'
export const CLEAR_INVOICE_ERRORS='CLEAR_ERRORS'
export const CLEAR_TYRE_ERRORS='CLEAR_TYRE_ERRORS'
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const PASS_CHANGE = 'PASS_CHANGE'
export const PASS_CHANGE_ERROR = 'PASS_CHANGE_ERROR'

export const GET_USERTYPES = 'GET_USERTYPES'
export const GET_USERTYPES_ERROR = 'GET_USERTYPES_ERROR'

export const GET_DEBT_EXECUTIVES = "GET_DEBT_EXECUTIVES"
export const GET_DEBT_EXECUTIVES_ERROR = "GET_DEBT_EXECUTIVES_ERROR"

export const GET_ALL_USERS = "GET_ALL_USERS"
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR"

export const ACTIVATE_USERS = 'ACTIVATE_USERS'
export const ACTIVATE_USERS_ERROR = 'ACTIVATE_USER_ERROR'
export const DEACTIVATE_USERS = 'DEACTIVATE_USERS'
export const DEACTIVATE_USERS_ERROR = 'DEACTIVATE_USERS_ERROR'


export const APPROVE_USER = "APPROVE_USER"
export const APPROVE_USER_ERROR = "APPROVE_USER_ERROR"

export const GET_APPROVE_USER = "GET_APPROVE_USER"
export const GET_APPROVE_USER_ERROR = "GET_APPROVE_USER_ERROR"

export const REGISTER_EXECS = "REGISTER_EXECS"
export const REGISTER_EXECS_ERROR = "REGISTER_EXECS_ERROR"

export const SEARCH_BY_INVOICE = "SEARCH_BY_INVOICE"
export const SEARCH_BY_INVOICE_ERROR = "SEARCH_BY_INVOICE_ERROR"

export const ASSIGN_USERS ="ASSIGN_USERS"
export const ASSIGN_USERS_ERROR ="ASSIGN_USERS_ERROR"

export const REASSIGN_USERS ="REASSIGN_USERS"
export const REASSIGN_USERS_ERROR ="REASSIGN_USERS_ERROR"

export const GET_INVOICE_STATUS = "GET_INVOICE_STATUS"
export const GET_INVOICE_STATUS_ERROR = "GET_INVOICE_STATUS_ERROR"

export const GET_CUSTOMER_INVOICE_STATUS = "GET_CUSTOMER_INVOICE_STATUS"
export const GET_CUSTOMER_INVOICE_STATUS_ERROR = "GET_CUSTOMER_INVOICE_STATUS_ERROR"

export const GET_STATS = "GET_STATS"
export const GET_STATS_ERROR = "GET_STATS_ERROR"


export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_ERROR = "GET_CUSTOMERS_ERROR"