import React, { useState, useContext, useEffect } from "react";
import AlertContext from "../../context/alert/AlertContext";
import AuthContext from "../../context/auth/AuthContext";
import Alert from "../layout/Alert";
import Spinner from "../layout/Spinner";
import { useForm } from "react-hook-form";
import logo from "../../media/nation_logo_full.png"

const InvoiceAssign = () => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const { register, handleSubmit, reset } = useForm();

  const { GetInvoiceAssign, error, clearErrors, invoice,getCollectors,
    collectors,AssignUsers,ReAssignUsers,assign,reassign } = authContext;
  const { setAlert } = alertContext;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
      getCollectors()
      if(assign){
        GetInvoiceAssign(invoiceid1)
          setVisible(false)
          setAlert(assign.message, "success")
          clearErrors()
      }
      if(reassign){
        GetInvoiceAssign(invoiceid1)
        setVisible(false)
        setAlert(reassign.message, "success")
        clearErrors()
    }
    if (invoice) {
      //   props.history.push("/dashboard");
      // console.log(invoice);
      setVisible(false);
      //   clearPackageErrors()
    }
    if (error) {
    //   setAlert(error.data.message, "danger");
      console.log(error);
      setVisible(false);
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, GetInvoiceAssign,assign,reassign,invoice]);
  const [invoiceid, setInvoice] = useState({
    invoiceid1: "",
  });

  let status = [];

  if (invoice) {
    status = invoice.slice(-1);
  } else {
    status = [];
  }

  // console.log(status);

  const { invoiceid1 } = invoiceid;

  const onSubmit = (e) => {
    e.preventDefault();
    if (invoiceid1 === "") {
      setAlert("Please enter all fields", "danger");
    } else {
      GetInvoiceAssign(invoiceid1);
      setVisible(true);
    }
  };

  const onChange = (e) => {
    setInvoice({ ...invoiceid, [e.target.name]: e.target.value });
  };

  let loaded_users = [];
  if (collectors) {
    loaded_users=collectors
  } else {
    loaded_users = [];
  }

  const Assign = (data, e) => {
    e.preventDefault();
    console.log(data.invoiceid,data.collectorid);
    let invoiceid = parseInt(data.invoiceid)
    let collectorid = parseInt(data.collectorid)
    AssignUsers({invoiceid,collectorid})
    reset()
    setVisible(true)
  }

  const ReAssign = (data, e) => {
    // e.preventDefault();
    console.log(data.invoiceid,data.collectorid);
    let invoiceid = parseInt(data.invoiceid)
    let collectorid = parseInt(data.collectorid)
    ReAssignUsers({invoiceid,collectorid})
    reset()
    setVisible(true)
  }
  return (
    <div>
      <div className="auth-wrapperUs mt-5">
        <div className="auth-innerUs">
          <Alert />
          {visible ? (
            <div>
              <h5 className="text-center">Searching...</h5>
              <Spinner />
            </div>
          ) : (
            <div></div>
          )}
          <img src={logo} className=" rounded mx-auto d-block" style={{ height: "5em", width: "13em" }} alt="" />
          <form onSubmit={onSubmit}>
            <h3 className="text-center">Invoices</h3>

            <div className="form-group">
              <label>Invoice Number</label>
              <input
                type="number"
                name="invoiceid1"
                value={invoiceid1}
                onChange={onChange}
                className="form-control"
                placeholder="Enter Invoice Number"
                required
              />
            </div>
            <div class="d-flex justify-content-center">
            <button type="submit" className="btn btn-primary btn-block mt-3">
              Search Invoice
            </button>
            </div>
          </form>
        </div>
      </div>

      <hr className="divider mt-5" />
      <h3 className="text-center">Invoice</h3>
      {/* waybill packages */}
      <div className="card">
        <div className="card-body">
          {status !== null ? (
            <section className="content-info dedicated">
              <form >
                {status.map((option) => (
                    <div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label htmlFor="email">Invoice No.</label>
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          value={option.transnum}
                          // onChange={onChange}
                          // required
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label htmlFor="email">Reference No.</label>
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          value={option.refnumber}
                          // onChange={onChange}
                          // required
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label htmlFor="email">Reference Text</label>
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          value={option.referencetext}
                          // onChange={onChange}
                          // required
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label htmlFor="email">Pay Terms</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        value={option.invpayterms}
                        // onChange={onChange}
                        // required
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label htmlFor="email">Due Date</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        value={option.invduedate}
                        // onChange={onChange}
                        // required
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label htmlFor="email">PO No.</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        value={option.ponumber}
                        // onChange={onChange}
                        // required
                        disabled
                      />
                    </div>
                    </div>
                  </div>
                  <div className="row">
                      <div className="col-lg-4">
                      <div className="form-group">
                      <label htmlFor="email">Customer No.</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        value={option.customerid}
                        // onChange={onChange}
                        // required
                        disabled
                      />
                    </div>
                      </div>
                      <div className="col-lg-4">
                      <div className="form-group">
                      <label htmlFor="email">Ivoice Total Cost</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        value={option.invtotalcost}
                        // onChange={onChange}
                        // required
                        disabled
                      />
                    </div>
                      </div>
                      <div className="col-lg-4">
                      <div className="form-group">
                      <label htmlFor="email">Amount Paid.</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        value={option.invamountpaid}
                        // onChange={onChange}
                        // required
                        disabled
                      />
                    </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-lg-4">
                      <div className="form-group">
                      <label htmlFor="email">Assigned?</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        value={option.active}
                        // onChange={onChange}
                        // required
                        disabled
                      />
                    </div>
                      </div>
                      <div className="col-lg-4">
                      <div className="form-group">
                      <label htmlFor="email">Assigned To</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        value={option.collector}
                        // onChange={onChange}
                        // required
                        disabled
                      />
                    </div>
                      </div>
                      <div className="row">
                          <div className="col-lg-4">
                          <div className="form-group">
                          <input className="mb-5 text-center invisible d-none form-control" 
                       type="number" 
                       name="invoiceid"
                        value={option.id}
                       {...register("invoiceid", { required: true })}
                       
                       />
                <label>Assign To</label>
                <select
                  name="collectorid"
                  className="form-control selectpicker"
                  data-live-search="true"
                  {...register("collectorid", { required: true })}
                  required
                >
                  <option value="">--select--</option>
                  {loaded_users.map((option) => (
                    <option key={option.id} value={option.id}>
                    {option.first_name} {option.last_name}
                  </option>
                  ))}
                </select>
              </div>
                          </div>
                      </div>
                  </div>
                  {
                      option.active?(
<button type="btton" className="btn btn-primary mt-5" onClick={handleSubmit(ReAssign)}>Reassign</button>
                      ):(
<button type="button" className="btn btn-primary mt-5" onClick={handleSubmit(Assign)}>Assign</button>
                      )
                  }
                </div>
                ))}
              </form>
            </section>
          ) : (
            <div>
              {/* <h5 className="text-center">
                Loading packages, please wait...
              </h5>
              <Spinner /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceAssign;
