import React, { useReducer } from "react";
import axios from "axios";
import InvoiceContext from "./InvoiceContext";
import InvoiceReducer from "./InvoiceReducer";
import {

  CLEAR_INVOICE_ERRORS, GET_CUSTOMERS, GET_CUSTOMERS_ERROR, GET_CUSTOMER_INVOICE_STATUS, GET_CUSTOMER_INVOICE_STATUS_ERROR,
  
} from "../types";

const AuthState = (props) => {
  const initialState = {
    invoice_loading: true,
    invoice_error: null,
    getcustomerinvoices:null,
    customers:null,
    
  };

  const server_url = process.env.REACT_APP_SERVER_DOMAIN;

  const [state, dispatch] = useReducer(InvoiceReducer, initialState);

  const clearInvoiceErrors = () => {
    dispatch({
      type: CLEAR_INVOICE_ERRORS
    });
  };


// Get Customer Invoices
const GetCustomerInvoices = async (customerid) => {
    try {
      const res = await axios.get(`${server_url}invoice/customerinvoice/${customerid}`);
      dispatch({
        type: GET_CUSTOMER_INVOICE_STATUS,
        payload: res.data,
      });
      // console.log(res.data);
    } catch (err) {
      dispatch({
        type: GET_CUSTOMER_INVOICE_STATUS_ERROR,
        payload: err.response,
      });
      console.log(err.response);
    }
  };


// Get Customers
const GetCustomers = async () => {
    try {
      const res = await axios.get(`${server_url}invoice/customers`);
      dispatch({
        type: GET_CUSTOMERS,
        payload: res.data,
      });
      // console.log(res.data);
    } catch (err) {
      dispatch({
        type: GET_CUSTOMERS_ERROR,
        payload: err.response,
      });
      console.log(err.response);
    }
  };


  return (
    <InvoiceContext.Provider
      value={{
        ...state,
        GetCustomerInvoices,
        clearInvoiceErrors,
        GetCustomers,
      }}
    >
      {props.children}
    </InvoiceContext.Provider>
  );
};

export default AuthState;
