import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/AuthContext";
import AlertContext from "../../context/alert/AlertContext";
import Alert from "../layout/Alert";
// import Paginations from '../../utils/Pagination'
import Spinner from "../layout/Spinner";
import { Link } from 'react-router-dom';

const InvoiceStatus = () => {
    const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const {
    getInvoiceStatus,
    invoicestatus,
    loading,
  } = authContext;
  useEffect(() => {
    getInvoiceStatus();
    // if (activatedUser) {
    //     if (activatedUser.message === "User Activated") {
    //       setAlert(activatedUser.message, "success");
    //       clearErrors();
    //     }
    //   }
    //   if (deactivatedUser) {
    //     if (deactivatedUser.message === "User Blocked") {
    //       setAlert(deactivatedUser.message, "success");
    //       clearErrors();
    //     }
    //   }
    // eslint-disable-next-line
  }, []);
  console.log(invoicestatus);
  let loaded_invoices = [];
  if (invoicestatus) {
    loaded_invoices=invoicestatus
  } else {
    loaded_invoices = [];
  }

    return (
        <div>
             <h2>Invoice status</h2>
            <Alert />
            {invoicestatus !== null && !loading ? (
              <section className="content-info overflow-auto">
                <table className="table table-striped table-bordered table-hover">
                  <thead className="">
                    <tr>
                      <th className="text-center"> Invoice No.</th>
                      <th className="text-center">Reference No.</th>
                      <th className="text-center">Reference Txt</th>
                      <th className="text-center">Due Date</th>
                      <th className="text-center">Total Amount</th>
                      <th className="text-center">Paid Amount</th>
                      <th className="text-center">Pay Terms</th>
                      <th className="text-center">Assigned To</th>
                      
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {loaded_invoices.map((option) => (
                      <tr key={option.transnum}>
                        <td>{option.transnum}</td>
                        <td>{option.refnumber}</td>
                        <td>{option.referencetext}</td>
                        <td>{option.invduedate}</td>
                        <td>{option.invtotalcost}</td>
                        <td>{option.invamountpaid}</td>
                        <td>{option.invpayterms}</td>
                        <td>{option.collector}</td>
                        {/* <td>
                          {option.active ? (
                            <p className="text-primary">Active</p>
                          ) : (
                            <p className="text-danger">Inactive</p>
                          )}
                        </td> */}
                        {/* <td>
                        <Link to={`/edit_user/${option.id}`}
                          className="btn btn-success btn-sm"
                        >
                          Edit
                        </Link>
                        </td> */}
                        {/* <td>
                          {option.is_active ? (
                            <button
                              className="btn btn-danger btn-sm"
                            //   onClick={Deactivate(option.id)}
                              type="button"
                            >
                              Block
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary btn-sm"
                            //   onClick={Activate(option.id)}
                              type="button"
                            >
                              Activate
                            </button>
                          )}
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination-wrapper mt-6">
                  <div>
          </div>
        </div>
              </section>
            ) : (
              <div>
                <h5 className="text-center">Loading invoices, please wait...</h5>
                <Spinner />
              </div>
            )}
          </div>
        
    )
}

export default InvoiceStatus
