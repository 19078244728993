import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/AuthContext";
import AlertContext from "../../context/alert/AlertContext";
import Alert from "../layout/Alert";
// import Paginations from '../../utils/Pagination'
import Spinner from "../layout/Spinner";
import { Link } from 'react-router-dom';

const ApproveUser = () => {
    const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const [visible, setVisible] = useState(false);
  const {
    getApproveUsers,
    approveusers,
    loading,
    ApproveUser,
    Approved,
    clearErrors,
    error
  } = authContext;
  useEffect(() => {
    getApproveUsers();
    // console.log(Approved);
    if (Approved) {
        // console.log(Approved);
          setAlert(Approved.message, "success");
          clearErrors();
          setVisible(false)
        
      }
      if(error){
        console.log(error);
        clearErrors();
          setVisible(false)
      }

    // eslint-disable-next-line
  }, [approveusers,Approved,error]);

//   console.log(Approved);
  let loaded_users = [];
  if (approveusers) {
    loaded_users=approveusers
  } else {
    loaded_users = [];
  }

//   console.log(loaded_users.length);

  const Activate = (item) => (e) => {
    e.preventDefault();
    ApproveUser(item)
    setVisible(true)
    
  };

    return (
        <div>
             <h2>Staff</h2>
            <Alert />
            {visible ? (
            <div>
              <h5 className="text-center">Approving...</h5>
              <Spinner />
            </div>
          ) : (
            <div></div>
          )}
            <div>
              {/* <div className="card mb-2 text-center border border-primary">
                <div >
                <Link to="/register"
                type="button"
                className="btn btn-primary mb-1 mt-1"
              >
                Add Staff
              </Link>
                </div>
              </div> */}
            </div>
            {approveusers !== null && !loading ? (
              <section className="content-info overflow-auto">
                <table className="table table-striped table-bordered table-hover">
                  <thead className="">
                    <tr>
                      <th className="text-center"> Name</th>
                      <th className="text-center">Title</th>
                      <th className="text-center">Email</th>
                      {/* <th className="text-center">Status</th> */}
                      {/* <th className="text-center">Edit</th> */}
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {loaded_users.map((option) => (
                      <tr key={option.id}>
                        <td>{option.first_name} {option.last_name}</td>
                        <td>{option.usertype.description}</td>
                        <td>{option.email}</td>
                        {/* <td>
                          {option.is_active ? (
                            <p className="text-primary">Active</p>
                          ) : (
                            <p className="text-danger">Blocked</p>
                          )}
                        </td> */}
                        {/* <td>
                        <Link to={`/edit_user/${option.id}`}
                          className="btn btn-success btn-sm"
                        >
                          Edit
                        </Link>
                        </td> */}
                        <td>
                          
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={Activate(option.id)}
                              type="button"
                            >
                              Approve
                            </button>
                          
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination-wrapper mt-6">
                  <div>
          </div>
        </div>
              </section>
            ) : (
              <div>
                <h5 className="text-center">Loading users, please wait...</h5>
                <Spinner />
              </div>
            )}
          </div>
        
    )
}


export default ApproveUser
