/* eslint-disable import/no-anonymous-default-export */
import {
  CLEAR_INVOICE_ERRORS,
  GET_CUSTOMERS,
  GET_CUSTOMERS_ERROR,
  GET_CUSTOMER_INVOICE_STATUS,
  GET_CUSTOMER_INVOICE_STATUS_ERROR,
} from "../types";

const InvoiceReducer = (state, action) => {
  switch (action.type) {
    case GET_CUSTOMER_INVOICE_STATUS:
      return {
        ...state,
        getcustomerinvoices: action.payload,
        invoice_loading: false,
      };

    case GET_CUSTOMER_INVOICE_STATUS_ERROR:
    case GET_CUSTOMERS_ERROR:
      return {
        ...state,
        invoice_loading: false,
        invoice_error: action.payload,
      };

    case GET_CUSTOMERS:
      return {
        ...state,
        invoice_loading: false,
        customers: action.payload,
      };

    case CLEAR_INVOICE_ERRORS:
      return {
        ...state,
        invoice_error: null,
      };
    default:
      return state;
  }
};

export default InvoiceReducer;
