import React, { useState, useContext, useEffect } from "react";
import AlertContext from "../../context/alert/AlertContext";
import AuthContext from "../../context/auth/AuthContext";
import InvoiceContext from "../../context/invoice/InvoiceContext";
import Alert from "../layout/Alert";
import Spinner from "../layout/Spinner";
import { useForm } from "react-hook-form";
import logo from "../../media/nation_logo_full.png"
import { Typeahead } from "react-bootstrap-typeahead";

const CustomerAssign = () => {
    const alertContext = useContext(AlertContext);
    const authContext = useContext(AuthContext);
    const invoiceContext = useContext(InvoiceContext)
    const { register, handleSubmit, reset } = useForm();
  
    const { GetInvoiceAssign, error, clearErrors, invoice,getCollectors,
      collectors,AssignUsers,ReAssignUsers,assign,reassign } = authContext;
    const { setAlert } = alertContext;
    const {GetCustomerInvoices,clearInvoiceErrors,invoice_error,getcustomerinvoices,invoice_loading,customers,GetCustomers} = invoiceContext
  
    const [visible, setVisible] = useState(false);
    const [singleSelections, setSingleSelections] = useState([]);

    let customerid = "";
  if (singleSelections && singleSelections[0] !== undefined) {
    customerid = singleSelections[0].customerid;
  } else {
    customerid = "";}
  
    useEffect(() => {
        getCollectors()
        GetCustomers()
        if(assign){
          GetInvoiceAssign(invoiceid1)
            setVisible(false)
            setAlert(assign.message, "success")
            clearErrors()
        }
        if(reassign){
          GetInvoiceAssign(invoiceid1)
          setVisible(false)
          setAlert(reassign.message, "success")
          clearErrors()
      }
      if (getcustomerinvoices) {
        //   props.history.push("/dashboard");
        // console.log(invoice);
        setVisible(false);
        //   clearPackageErrors()
      }
      if (error) {
      //   setAlert(error.data.message, "danger");
        console.log(error);
        setVisible(false);
        clearErrors();
      }
      // eslint-disable-next-line
    }, [error, GetInvoiceAssign,assign,reassign,invoice, getcustomerinvoices]);
    const [invoiceid, setInvoice] = useState({
      invoiceid1: "",
    });

    console.log(getcustomerinvoices);
  
    let status = [];
  
    if (invoice) {
      status = invoice.slice(-1);
    } else {
      status = [];
    }


    let stateCustomers = []

    if(customers){
        stateCustomers = customers
    }else{
        stateCustomers = []
    }
  
    // console.log(status);
  
    const { invoiceid1 } = invoiceid;
  
    const onSubmit = (e) => {
      e.preventDefault();
      if (customerid === "") {
        setAlert("Please enter all fields", "danger");
      } else {
        GetCustomerInvoices(customerid);
        setVisible(true);
      }
    };
  
    const onChange = (e) => {
      setInvoice({ ...invoiceid, [e.target.name]: e.target.value });
    };
  
    let loaded_users = [];
    if (collectors) {
      loaded_users=collectors
    } else {
      loaded_users = [];
    }
  
    const Assign = (data, e) => {
      e.preventDefault();
      console.log(data.invoiceid,data.collectorid);
      let invoiceid = parseInt(data.invoiceid)
      let collectorid = parseInt(data.collectorid)
      AssignUsers({invoiceid,collectorid})
      reset()
      setVisible(true)
    }
  
    const ReAssign = (data, e) => {
      // e.preventDefault();
      console.log(data.invoiceid,data.collectorid);
      let invoiceid = parseInt(data.invoiceid)
      let collectorid = parseInt(data.collectorid)
      ReAssignUsers({invoiceid,collectorid})
      reset()
      setVisible(true)
    }
    return (
      <div>
        <div className="auth-wrapperUs mt-5">
          <div className="auth-innerUs">
            <Alert />
            {visible ? (
              <div>
                <h5 className="text-center">Searching...</h5>
                <Spinner />
              </div>
            ) : (
              <div></div>
            )}
            <img src={logo} className=" rounded mx-auto d-block" style={{ height: "5em", width: "13em" }} alt="" />
            <form onSubmit={onSubmit}>
              <h3 className="text-center">Customer Assign</h3>
  
              <div className="form-group">
                <label>Customer Number</label>
                {/* <input
                  type="number"
                  name="invoiceid1"
                  value={invoiceid1}
                  onChange={onChange}
                  className="form-control"
                  placeholder="Enter Customer Number"
                  required
                /> */}
                {stateCustomers.length !== 0 && (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="customername"
                  onChange={setSingleSelections}
                  options={stateCustomers}
                  placeholder="Select Sender"
                  selected={singleSelections}
                  size="md"
                  inputProps={{ required: true }}
                />
              )}
              </div>

              <div className="form-group">
              
            </div>
            <div class="d-flex justify-content-center">
              <button type="submit" className="btn btn-primary btn-block mt-3">
                Search Customer
              </button>
              </div>
            </form>
          </div>
        </div>
  
        <hr className="divider mt-5" />
        <h3 className="text-center">Customer Invoices</h3>
        {/* waybill packages */}
        {getcustomerinvoices !== null && !invoice_loading ? (
              <section className="content-info overflow-auto">
                <table className="table table-striped table-bordered table-hover">
                  <thead className="">
                    <tr>
                      <th className="text-center"> Invoice No.</th>
                      <th className="text-center">Reference No.</th>
                      <th className="text-center">Reference Txt</th>
                      <th className="text-center">Due Date</th>
                      <th className="text-center">Total Amount</th>
                      <th className="text-center">Paid Amount</th>
                      <th className="text-center">Pay Terms</th>
                      <th className="text-center">Assigned To</th>
                      
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {getcustomerinvoices.map((option) => (
                      <tr key={option.transnum}>
                        <td>{option.transnum}</td>
                        <td>{option.refnumber}</td>
                        <td>{option.referencetext}</td>
                        <td>{option.invduedate}</td>
                        <td>{option.invtotalcost}</td>
                        <td>{option.invamountpaid}</td>
                        <td>{option.invpayterms}</td>
                        <td>{option.collector}</td>
                        {/* <td>
                          {option.active ? (
                            <p className="text-primary">Active</p>
                          ) : (
                            <p className="text-danger">Inactive</p>
                          )}
                        </td> */}
                        {/* <td>
                        <Link to={`/edit_user/${option.id}`}
                          className="btn btn-success btn-sm"
                        >
                          Edit
                        </Link>
                        </td> */}
                        {/* <td>
                          {option.is_active ? (
                            <button
                              className="btn btn-danger btn-sm"
                            //   onClick={Deactivate(option.id)}
                              type="button"
                            >
                              Block
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary btn-sm"
                            //   onClick={Activate(option.id)}
                              type="button"
                            >
                              Activate
                            </button>
                          )}
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination-wrapper mt-6">
                  <div>
          </div>
        </div>
              </section>
            ) : (
              <div>
                {/* <h5 className="text-center">Loading invoices, please wait...</h5>
                <Spinner /> */}
              </div>
            )}
      </div>
    );
  };
export default CustomerAssign